import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.4575 2.45538C21.3413 2.35504 21.1999 2.28831 21.0486 2.26237C20.8973 2.23643 20.7417 2.25228 20.5987 2.3082L1.59937 9.74351C1.3301 9.84821 1.10214 10.0376 0.949797 10.283C0.797454 10.5285 0.728977 10.8168 0.754676 11.1046C0.780374 11.3923 0.898857 11.664 1.09229 11.8786C1.28572 12.0932 1.54362 12.2391 1.82718 12.2944L6.74999 13.261V18.7501C6.74902 19.0491 6.83787 19.3414 7.00503 19.5893C7.17219 19.8372 7.40995 20.0292 7.68749 20.1404C7.96461 20.2535 8.26936 20.2807 8.56213 20.2183C8.85489 20.156 9.12211 20.007 9.32906 19.7907L11.7028 17.3288L15.4687 20.6251C15.7404 20.8661 16.0909 20.9994 16.4541 21.0001C16.6132 20.9999 16.7714 20.975 16.9228 20.926C17.1703 20.8475 17.3928 20.7055 17.5683 20.5142C17.7438 20.3229 17.8662 20.089 17.9231 19.8357L21.7284 3.28132C21.7625 3.13202 21.7552 2.97628 21.7075 2.83078C21.6598 2.68528 21.5734 2.55551 21.4575 2.45538ZM15.7256 5.83038L7.32656 11.8454L2.67656 10.9332L15.7256 5.83038ZM8.24999 18.7501V14.2988L10.5741 16.3369L8.24999 18.7501ZM16.4559 19.5001L8.70468 12.7032L19.8609 4.70726L16.4559 19.5001Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Icon
