import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'SF Pro';
    src: url('/assets/font/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('/assets/font/SF-Pro-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('/assets/font/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('/assets/font/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('/assets/font/SF-Pro-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('/assets/font/SF-Pro-Display-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'SF Pro';
    src: url('/assets/font/SF-Pro-Display-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }

  * {
    font-family: 'SF Pro';
    color: #FFF; 
  }

  body {
    line-height: 1.25;
    font-weight: 400;
    font-size: 14px;
    background: #000000;
    min-height: fit-content !important;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 16px;
    }
  }

  .container {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    padding: 0 14px;
    margin: 0 auto;
  }

  .underline {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    background: linear-gradient(99.46deg, #fa00ff -10.9%, #00e0ff 97.13%, #2ad4f9 97.14%);
    height: 2px;
    width: 100%;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .error {
    font-size: 12px;
    color: #FF4842;
  }


  .ant-layout{
    overflow-x: hidden;
  }

  .ant-form-item-explain-error {
    color: #FF0F00
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
    border-color: #FF0F00 !important;
  }

  // ant select dropdown
  .simple-select {
    .ant-form-item {
      margin: 0;
    }
    .ant-select-dropdown {
      padding: 0; 
      background: #161c1c; 
      border-radius: 4px; 
      overflow: hidden;
    }
    .ant-select-item {
      color: rgb(230 247 255); 
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      color: #00A0FF; 
      background: #161c1c; 
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: #00A0FF; 
      background: #161c1c; 
    }
    .ant-select-arrow {
      img {
        transform: rotate(180deg);
      }
    }
    .ant-select-open .ant-select-arrow {
      img {
        transform: rotate(0deg);
      }
    }
    
  }

  #google_translate_element, .skiptranslate iframe{
    display: none;
  }

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00A0FF;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;
    height: auto;
    border: 1px solid #00A0FF;

    &:disabled {
      background: transparent;
      color: #00A0FF;
      border: 1px solid #00A0FF;
    }

    .ant-btn-loading-icon{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active{
      background: #00A0FF !important;
      color: #0D0F07 !important;

      &:hover {
        box-shadow: 0px 4px 20px #00A0FF;
      }
    }

    &:hover {
      background: #00A0FF !important;
      color: #0D0F07 !important;
      border: 1px solid transparent;
    }

    &:focus {
      color: #00A0FF;
      border: 1px solid #00A0FF;
    }
  }

  .ant-modal-title{
    color: #FDFFFA;
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700;

    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .ant-pagination-item{
    &:hover {
      border-color: #00A0FF;
    
      a{
        color: #00A0FF;
      }
    }
  }

  .ant-pagination-item-active, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link{
    border-color: #00A0FF;
    color: #00A0FF;
    
    a{
      color: #00A0FF;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #00A0FF !important;
      border-color: #00A0FF;

      &::after {
        border: 3px solid #FFF;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: #00A0FF
      }
    }
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;

    &::after {
      width: 7px;
      height: 15px;
    }

    &:hover,
    &:focus {
      border-color: #00A0FF !important;
    }
  }

  .history-select-popup {
    min-width: 175px !important;

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: transparent;

      .ant-select-item-option-content {
        color: #00A0FF;
      }
    }
  }
  
  .ant-modal-content {
    border-radius: 36px 0 36px 36px;
    border: 1px solid #8F90A6;
    background: #0B0D27;
    box-shadow: none;

    .ant-modal-body {
      padding: 24px 16px;

      ${({ theme }) => theme.mediaQueries.md} {
        padding: 24px;
      }
    }
  }

  .ant-modal-close {
    top: -20px;
    right: -15px;
    transform: scale(.75);  

    ${({ theme }) => theme.mediaQueries.md} {
      transform: scale(1);
      top: -10px;
      right: -10px;  
    }
  }

  .ant-modal-wrap {
    &.modal-without-background {
      .ant-modal-content {
        background-image: unset;
      }
    }
  }

  .ant-modal-mask{
    ${({ theme }) => theme.mediaQueries.md} {
      backdrop-filter: blur(6px);
    }
  }

  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 8px;
      border: 1px solid #71717a;
      height: auto;
      padding: 2px 16px;
    }

    .ant-select-selection-item {
      color: #FFF;
      font-size: 14px;
      font-weight: 700;
    }

    .ant-select-arrow {
      svg path {
        fill: #fff;
      }
    }
  }
`

export default GlobalStyle
