import clsx from 'clsx'
import { useTranslation } from 'contexts/Localization'

const HeroBanner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="relative">
      <video
        autoPlay
        controls={false}
        muted
        loop
        className="!w-full object-cover mx-auto h-[700px] lg:h-[800px] xl:h-[1080px]  max-w-[1920px]"
      >
        <source src="/images/home/Hero background.mp4" />
      </video>
      <div
        className={clsx(
          'absolute top-0 left-0 w-full h-full p-4',
          'pt-24',
          'md:pt-32',
          'lg:pt-44',
          'xl:p-32',
          'xl:pt-56',
        )}
      >
        <div className="container flex flex-col items-center gap-8">
          <div
            className={clsx(
              'flex flex-col items-center gap-3',
              'md:max-w-[400px]',
              'lg:max-w-[470px]',
              'xl:max-w-[547px]',
            )}
          >
            <p
              className={clsx(
                'text-center font-medium tracking-[-1.2px] text-gradient w-fit text-3xl',
                'md:text-4xl',
                'lg:text-5xl',
                'xl:leading-[72px] xl:text-6xl whitespace-nowrap',
              )}
            >
              {t('Your Trusted Software')}
              <br /> {t('Development Partner')}
            </p>
            <p className="text-[#ADADAD] xl:text-lg text-center">
              {t(
                `We bring together IT experts who leverage data-driven insights and cutting-edge digital transformation strategies to empower our clients. With extensive experience in software development, we foster sustainable growth for businesses of all sizes.`,
              )}
            </p>
          </div>
          <button className="tw-btn text-gradient xl:text-lg font-semibold" type="button">
            {t('Download Portfolio')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner
